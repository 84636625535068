<template>
  <div class="parameters">
    <PageTitle
      title="銷售模組參數設定"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 64px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <div class="parameters-category-list">
      <CategoryBlock
        v-for="param in ecommerceParametersRoutes[0].children"
        :key="param.name"
        :data="param"
      />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle'
import CategoryBlock from '../components/CategoryBlock.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SalesParameters',
  components: {
    CategoryBlock,
    PageTitle,
  },
  computed: {
    ...mapGetters([
      'asyncRoutes',
    ]),
    paramsRoutes () {
      return this.asyncRoutes.filter(route => route.name === 'Parameters')
    },
    ecommerceParametersRoutes () {
      if (this.paramsRoutes) {
        if (!this.paramsRoutes.length) return []
      }
      return this.paramsRoutes[0].children.filter(route => route.name === 'SalesParameterSetting')
    },

  },
  data: () => ({

  }),
}
</script>

<style lang="postcss" scoped>
.parameters-category-list {
  @apply grid grid-cols-3 gap-[44px];
}

@media screen and (max-width: 1200px) {
  .parameters-category-list {
    @apply grid grid-cols-2;
  }
}
</style>
